import { GetChatsParams, GetServicesParams, APIResponse, GetMessagesParams, Message, Service } from '../types'
import { sanitizeObject, setDefaultsForPagination } from './_helpers'
import { getSessionData, getToken } from './auth'
const BASE_URL = '/api'

/**
 * @param {GetChatsParams} params 
 * @returns {Promise<APIResponse>}
 */
export const getChats = async (params) => {
  const token = await getToken()
  const sessionData = getSessionData()
  const agent = sessionData?.users[0]?.id

  setDefaultsForPagination(params)
  sanitizeObject(params, GetChatsParams)
  if (!params.responsibleId && sessionData.account.permission === 'technician') params.responsibleId = sessionData.account.id
  const parameters = new URLSearchParams(params).toString()
  
  const response = await fetch(`${BASE_URL}/chat?${parameters}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      agent
    }
  })

  const result = await response.json()
  if (!response.ok) throw new Error(result.message)
  return result
}

/**
 * @param {GetMessagesParams} params 
 * @returns {Promise<APIResponse>}
 */
export const getMessages = async (params) => {
  const token = await getToken()
  const sessionData = getSessionData()
  const agent = sessionData?.users[0]?.id

  setDefaultsForPagination(params)
  sanitizeObject(params, GetMessagesParams)
  if (!params.responsibleId && sessionData.account.permission === 'technician') params.responsibleId = sessionData.account.id
  const parameters = new URLSearchParams(params).toString()
  
  const response = await fetch(`${BASE_URL}/chat/message?${parameters}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      agent
    }
  })

  const result = await response.json()
  if (!response.ok) throw new Error(result.message)
  return result
}

/**
 * @param {string} filename 
 * @returns {Promise<string>} fileUrl
 */
export const fetchMessageAttachment = async (filename) => {
  const token = await getToken()

  const response = await fetch(`${BASE_URL}/storage/message-attachments/${filename}`, {
    method: 'GET',
    headers: {
      'Authorization': `${token}`,
    },
  })

  if (!response.ok) throw new Error('Erro ao baixar mídia')
  const blob = await response.blob()
  const url = URL.createObjectURL(blob)
  return url
}

/**
 * @param {GetServicesParams} params 
 * @returns {Promise<APIResponse>}
 */
export const getServices = async (params) => {
  try {
    setDefaultsForPagination(params)
    sanitizeObject(params, GetServicesParams)

    const token = await getToken();
    const parameters = new URLSearchParams(params).toString()

    const response = await fetch(`${BASE_URL}/chat/service?${parameters}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar atendimentos');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar atendimentos:', error);
    return { data: [], links: { metaData: { total: 0, lastPage: 0 } } };
  }
}

/**
 * @param {GetServicesParams} params 
 * @returns {Promise<string>}
 */
export const downloadServicesCSV = async (params) => {
  try {
    setDefaultsForPagination(params)
    sanitizeObject(params, GetServicesParams)

    const token = await getToken();
    const parameters = new URLSearchParams(params).toString();

    const response = await fetch(`${BASE_URL}/chat/service/csv?${parameters}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar atendimentos');
    }

    const result = await response.text();
    return result;
  } catch (error) {
    console.error('Erro ao buscar atendimentos:', error);
    return { data: [], links: { metaData: { total: 0, lastPage: 0 } } };
  }
}

/**
 * 
 * @param {uuid} serviceId 
 * @returns {Promise<APIResponse>}
 */
export const getServiceById = async (serviceId) => {
  try {
    const token = await getToken();

    const response = await fetch(`${BASE_URL}/chat/service/${serviceId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar atendimento');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar atendimento:', error);
    return { result: null };
  };
};

/**
 * 
 * @param {uuid} serviceId 
 * @param {{ priorityLevel: "alta"|"indefinida", serviceTypeId: number|undefined }} service 
 * @returns {Promise<APIResponse>}
 */
export const putService = async (serviceId, service) => {
  const token = await getToken()
  sanitizeObject(service, Service)
  
  const rawBody = { service }
  const response = await fetch(`${BASE_URL}/chat/service/${serviceId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    },
    body: JSON.stringify(rawBody)
  })

  const result = await response.json()
  if (!response.ok) throw new Error(result.message)
  return result
}

/**
 * 
 * @param {Message} message 
 * @returns {Promise<APIResponse>}
 */
export const postMessage = async(producerAccountId, message) => {
  const token = await getToken()
  const sessionData = getSessionData()
  const agent = sessionData?.users[0]?.id

  const formData = new FormData()
  formData.append('hasMedia', message.hasMedia)
  message.content && formData.append('content', message.content)
  if (message.messageAttachment) {
    message.messageAttachment.forEach(attachment => {
      formData.append('messageAttachment', attachment)
    })
  }
  
  const response = await fetch(`${BASE_URL}/chat/${producerAccountId}/message/create`, {
    method: 'POST',
    headers: {
      'Authorization': token,
      'agent': agent
    },
    body: formData
  })

  const result = await response.json()
  if (!response.ok) throw new Error(result.message)
  return result
}