import { useEffect, useRef, useState } from 'react'
import { MaterialSymbol } from 'react-material-symbols'

import { useChatContext } from '../../../pages/Protected/Chats'
import { postMessage } from '../../../api/chats'

let imageStream
const Camera = ({ setChatContent }) => {
  const {
    currentUser,
    setError,
    chat,
    chatList, setChatList,
    isCameraActive, setIsCameraActive
  } = useChatContext()
  const [capturedPhotoBlob, setCapturedPhotoBlob] = useState()
  const videoStreamRef = useRef(null)
  const canvasRef = useRef(null)

  const createImageStream = async () => {
    if (imageStream) {
      await imageStream.getTracks().forEach(track => track.stop())
    }

    await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      .then(stream => {
        imageStream = stream
        videoStreamRef.current.srcObject = stream
      })  
      .catch(e => {
        imageStream?.getTracks().forEach(track => track.stop())
        console.error(e)
        setIsCameraActive(false)
        setError('Acesso ao microfone ou à câmera negados')
      })    
  }

  const takePhoto = async () => {
    if (!(canvasRef.current && videoStreamRef.current)) {
      setError('Erro ao abrir a câmera')
      return
    }

    canvasRef.current.width = videoStreamRef.current.videoWidth
    canvasRef.current.height = videoStreamRef.current.videoHeight

    const context = canvasRef.current.getContext('2d')
    context.translate(canvasRef.current.width, 0)
    context.scale(-1, 1)
    context.drawImage(videoStreamRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height)
    context.setTransform(1, 0, 0, 1, 0, 0)

    canvasRef.current.toBlob((blob) => {
      if (blob) setCapturedPhotoBlob(blob)
    }, 'image/png')
  }

  const savePhoto = async () => {
    if (capturedPhotoBlob) {
      postMessage(chat.producer.account.id, {
        hasMedia: true,
        messageAttachment: [capturedPhotoBlob]
      })
        .then (response => {
          const message = response.data?.at(0)
          const content = {
            type: 'messageSent',
            message: message,
            sender: currentUser.id,
            hasMedia: message.hasMedia,
            content: message.content,
            media: message.media,
            createdAt: message.createdAt
          }
    
          setChatContent(prev => ([...prev, content]))
          
          const chatListClone = structuredClone(chatList)
          const chatToEdit = chatListClone.find(item => item.producer.account.id === chat.producer.account.id)
          chatToEdit.message = message
          chatToEdit.lastMessage = message.content
          chatToEdit.media = message.media
          setChatList(chatListClone)
        })
        .catch(e => {
          console.error(e)
          setError('Erro ao postar foto')
        })
        .finally(_ => {
          setCapturedPhotoBlob()
          stopImageStream()
        })
    }
  }

  if (isCameraActive && !imageStream) {
    createImageStream()
  }

  const stopImageStream = async () => {
    await imageStream?.getTracks().forEach(track => track.stop())
    imageStream = null
    setIsCameraActive(false)
  }

  const restartCamera = async () => {
    setCapturedPhotoBlob()
    createImageStream()
  }

  return (
    <div key={capturedPhotoBlob} className='flex grow flex-col w-full h-full bg-black-1'>
      {!capturedPhotoBlob && (
        <div className='flex flex-col grow w-full bg-black-1 justify-center items-center relative'>
          <button className='absolute right-3 top-3' onClick={stopImageStream}>
            <MaterialSymbol
              icon='close'
              color='#FBFCF8'
              size={32}
            />
          </button>
          <video className='w-full' ref={videoStreamRef} style={{ transform: 'scaleX(-1)' }} muted autoPlay />
          <button className='absolute bottom-5' onClick={takePhoto}>
            <MaterialSymbol
              icon='photo_camera'
              color='#FBFCF8'
              size={32}
            />
          </button>
          <canvas ref={canvasRef} style={{ display: 'none' }} />
        </div>
      )}
      { capturedPhotoBlob && (
        <div className='flex flex-col grow w-full bg-black-1 justify-center items-center relative'>
          <button className='absolute right-3 top-3' onClick={restartCamera}>
            <MaterialSymbol
              icon='close'
              color='#FBFCF8'
              size={32}
            />
          </button>
          <img className='w-full' src={URL.createObjectURL(capturedPhotoBlob)} alt='captured' />
          <button className='absolute bottom-5' onClick={savePhoto}>
            <MaterialSymbol
              icon='check'
              color='#FBFCF8'
              size={32}
            />
          </button>
        </div>
      )}
    </div>
  )
}

export default Camera