export const sanitizeObject = (targetObject, templateObject) => {
  Object.entries(targetObject).map(([key, value]) => {
    if (!(key in templateObject)) delete targetObject[key]
    if (value === undefined || value === null || value === '') delete targetObject[key]
  })
}

export const setDefaultsForPagination = (targetObject) => {
  if (targetObject.paginate === 'false') {
    delete targetObject.page
    delete targetObject.limit
    return
  }

  if (!targetObject.page) targetObject.page = 1
  if (!targetObject.limit) targetObject.limit = 20
}

export const cleanBody = (body) => {
  return Object.entries(body).reduce((acc, [key, value]) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const cleanedValue = cleanBody(value)
      if (Object.keys(cleanedValue).length > 0) {
        acc[key] = cleanedValue
      }
    } else if (value !== null && value !== undefined && value !== '') {
      acc[key] = value
    }
    return acc
  }, {})
}