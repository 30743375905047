import { format } from 'date-fns'

import Body from '../../typography/Body'

const ChangeLog = ({ item }) => {
  const fillChangeLogContent = (item) => {
    let formattedTimestamp
    switch (item.logType) {
      case 'serviceClosed':
        formattedTimestamp = format(new Date(item.logInfo.endTimestamp), 'dd/MM/yyyy \'às\' HH:mm')
        item.content = `Atendimento #${item.logInfo.serviceNumber} finalizado em ${formattedTimestamp}`
        break
      case 'serviceOpened':
        formattedTimestamp = format(new Date(item.logInfo.startTimestamp), 'dd/MM/yyyy \'às\' HH:mm')
        item.content = `Atendimento #${item.logInfo.serviceNumber} iniciado em ${formattedTimestamp}`
        break
      case 'appointmentOpened':
        formattedTimestamp = format(new Date(item.logInfo.scheduledTo), 'dd/MM/yyyy \'às\' HH:mm')
        item.content = `Visita agendada para ${formattedTimestamp}`
        break
      case 'appointmentConfirmed':
        formattedTimestamp = format(new Date(item.logInfo.scheduledTo), 'dd/MM/yyyy \'às\' HH:mm')
        item.content = `Visita confirmada para ${formattedTimestamp}`
        break
      case 'appointmentCanceled':
        formattedTimestamp = format(new Date(item.logInfo.scheduledTo), 'dd/MM/yyyy \'às\' HH:mm')
        item.content = `Visita em ${formattedTimestamp} cancelada`
        break
      case 'appointmentClosed':
        formattedTimestamp = format(new Date(item.logInfo.scheduledTo), 'dd/MM/yyyy \'às\' HH:mm')
        item.content = `Visita concluída em ${formattedTimestamp}`
        break
      default:
        item.content = 'Log desconhecido'
    }
  }

  fillChangeLogContent(item)

  return (
    <div className='w-full grid justify-items-center'>
      <div className='flex px-xs py-xxs bg-green-1 max-w-[75%] w-fit rounded-xs' style={{ boxShadow: '0px 2px 4px rgba(21, 26, 10, 0.25)' }}>
        <Body size='md' style='text-center'>{item.content}</Body>
      </div>    
    </div>
  )
}

export default ChangeLog