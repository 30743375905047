import { useState } from 'react'
import { format } from 'date-fns'
import { MaterialSymbol } from 'react-material-symbols'

import Body from '../../typography/Body'
import Label from '../../typography/Label'

const AudioPlayer = ({ item, audioRef, errorOnLoad }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [position, setPosition] = useState(0)
  const [isDragging, setIsDragging] = useState(false)

  if (!errorOnLoad && audioRef.current?.src) {
    audioRef.current.addEventListener('loadedmetadata', () => {
      setDuration(audioRef.current.duration * 1000)
    })
  
    audioRef.current.addEventListener('timeupdate', () => {
      setPosition(audioRef.current.currentTime * 1000)
    })
  }

  const handlePlayPause = () => {
    if (errorOnLoad) return

    if (!isPlaying && audioRef.current) {
      audioRef.current.play()
        .then(_ => {
          setIsPlaying(true)
        })
        .catch(e => {
          console.error('Erro ao reproduzir mídia')
        })
      return
    }

    if (isPlaying && audioRef.current) {
      audioRef.current.pause()
      setIsPlaying(false)
      return
    }
  }

  const handleDrag = (e) => {
    e.preventDefault()
  
    if (!isDragging) return
  
    const rect = e.target.parentElement.getBoundingClientRect()
    const x = e.clientX - rect.left
    const clampedX = Math.max(0, Math.min(x, rect.width))
  
    const percent = clampedX / rect.width
    const newPosition = percent * duration
  
    setPosition(newPosition)
  
    if (audioRef.current) {
      audioRef.current.currentTime = newPosition / 1000
    }
  }
  
  const handleMouseDown = () => {
    setIsDragging(true)
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)

    if (audioRef.current) {
      audioRef.current.pause()
    }
  }
  
  const handleMouseUp = () => {
    setIsDragging(false)
    window.removeEventListener('mousemove', handleMouseMove)
    window.removeEventListener('mouseup', handleMouseUp)

    if (audioRef.current && isPlaying) {
      audioRef.current.play()
    }
  }

  const handleMouseMove = (e) => {
    if (!isDragging) return
    handleDrag(e)
  }

  return (
    <div 
      className={`flex flex-col px-xs py-xxs ${item.type === 'messageReceived' ? 'bg-white-1' : 'bg-green-2'} ${item.hasMedia ? ' rounded-b-xs' : 'rounded-xs'} max-w-full w-[800]`}
      style={{ boxShadow: '0px 2px 4px rgba(21, 26, 10, 0.25)' }}
    >
      {item.type === 'messageReceived' && (
        <Label size='md' style='text-green-4'>{item.sender.name}</Label>
      )}
      {!errorOnLoad && (
        <div>
          <div className={`flex flex-col ${item.type !== 'messageReceived' && 'pt-xs'} gap-xxxs`}>
            <div className='flex flex-row items-center gap-xs'>
              <button className='flex flex-col items-center' onClick={handlePlayPause}>
                <MaterialSymbol
                  icon={isPlaying ? 'pause' : 'play_arrow'}
                  color='#B27C1F'
                  size={32}
                  fill={1}
                />
              </button>
              <div className='w-[250px] h-xxs bg-gray-1 rounded-lg relative'>
                <div
                  className='absolute h-xxs bg-yellow-2 rounded-lg'
                  style={{ width: `${(position / duration) * 100}%` || '0%' }}
                ></div>
                <div
                  id='thumb'
                  className='absolute w-sm h-sm bg-yellow-3 rounded-full -top-[6px] cursor-pointer'
                  style={{ left: `calc(${(position / duration) * 100}% - 8px)` || '0px' }}
                  draggable='true'
                  onMouseDown={handleMouseDown}
                  // onMouseMove={handleMouseMove}
                  // onMouseUp={handleMouseUp}
                ></div>
              </div>
            </div>
            <Body size='sm' style='text-gray-3'>
              {position === 0
                ? new Date(duration).toISOString().substr(14, 5)
                : new Date(position).toISOString().substr(14, 5)}
            </Body>
          </div>
          <audio controls ref={audioRef} hidden />
        </div>
      )}
      { errorOnLoad && (
        <div>
          <Label size='lg' style='text-black-1 self-center'>{'{{ Áudio indisponível }}'}</Label>
        </div>
      )}
      <Label size='sm' style='text-gray-3 self-end'>{format(new Date(item.createdAt), 'HH:mm')}</Label>
    </div>
  )
}

export default AudioPlayer
