import { useState, useEffect, useRef } from 'react'
import { MaterialSymbol } from 'react-material-symbols'

import { useChatContext } from '../../../pages/Protected/Chats'
import ChatInputText from './ChatInputText'
import OptionsMenu from './OptionsMenu'
import Body from '../../typography/Body'
import { postMessage } from '../../../api/chats'

const ChatTypeBar = ({ setChatContent }) => {
  const {
    currentUser,
    setError,
    chat,
    chatList, setChatList,
    isOptionsMenuVisible, setIsOptionsMenuVisible,
    setIsCameraActive
  } = useChatContext()
  const [messageContent, setMessageContent] = useState('')
  const [isMediaViewerVisible, setIsMediaViewerVisible] = useState(false)
  const [isRecordingAudio, setIsRecordingAudio] = useState(false)
  const [shouldStoreAudio, setShouldStoreAudio] = useState(true)
  const [recordingTime, setRecordingTime] = useState(0)
  const [audioUrl, setAudioUrl] = useState()
  const mediaChunksRef = useRef([])
  const audioRecorderRef = useRef(null)

  const sendMessage = async () => {
    if (!chat.producer) return

    postMessage(chat.producer.account.id, {
      hasMedia: false,
      content: messageContent
    })
    .then(response => {
      setMessageContent('')
      const message = response.data?.at(0)
      const content = {
        type: 'messageSent',
        message: message,
        sender: currentUser.id,
        hasMedia: message.hasMedia,
        content: message.content,
        createdAt: message.createdAt
      }

      setChatContent(prev => ([...prev, content]))
      
      const chatListClone = structuredClone(chatList)
      const chatToEdit = chatListClone.find(item => item.producer.account.id === chat.producer.account.id)
      chatToEdit.message = message
      chatToEdit.lastMessage = message.content
      setChatList(chatListClone)
    })
    .catch(_ => {
      setError('Erro ao enviar mensagem')
    })
  }

  const createAudioRecorder = async () => {
    const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true }).catch(_ => {})

    if (!audioStream) return setError('Acesso ao microfone negado')

    audioRecorderRef.current = new MediaRecorder(audioStream)

    audioRecorderRef.current.ondataavailable = (event) => {
      mediaChunksRef.current.push(event.data)
    }

    audioRecorderRef.current.onstop = () => {
      if (!shouldStoreAudio) {
        mediaChunksRef.current.length = 0
        setShouldStoreAudio(!shouldStoreAudio)
        return
      }

      const audioBlob = new Blob(mediaChunksRef.current, { type: 'audio/wav' })
      const audioBlobUrl = URL.createObjectURL(audioBlob)
      mediaChunksRef.current.length = 0
      setAudioUrl(audioBlobUrl)
    }
  }

  const startAudioRecording = async () => {
    if (!audioRecorderRef.current) await createAudioRecorder()

    if (audioRecorderRef.current && (audioRecorderRef.current instanceof MediaRecorder)) {
      setIsRecordingAudio(true)
      audioRecorderRef.current.start()
    }
  }

  const cancelAudioRecording = async () => {
    if (audioRecorderRef.current && (audioRecorderRef.current instanceof MediaRecorder)) {
      setShouldStoreAudio(!shouldStoreAudio)
      audioRecorderRef.current.stop()
      setIsRecordingAudio(false)
    }
  }

  const handleSendAudio = async () => {
    audioRecorderRef.current.stop()
    setIsRecordingAudio(false)
  }

  useEffect(() => {
    if (audioUrl) {
      setAudioUrl()
    }
  }, [audioUrl])

  const formatRecordingTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  useEffect(() => {
    let interval
    if (isRecordingAudio) {
      interval = setInterval(() => {
        setRecordingTime(prevTime => prevTime + 1)
      }, 1000)
    } else {
      clearInterval(interval)
      setRecordingTime(0)
    }

    return () => clearInterval(interval)
  }, [isRecordingAudio])

  return (
    <div
      className='flex bg-white rounded-t-lg'
      style={{ boxShadow: '0px 2px 4px rgba(21, 26, 10, 0.25)' }}
    >
      <div className='flex flex-row items-center pl-md pr-sm py-sm w-full gap-sm'>
        {!isRecordingAudio && !isOptionsMenuVisible && (
          <ChatInputText
            placeholder='Mensagem'
            onChangeText={setMessageContent}
            value={messageContent}
            className='leading-[125%] text-[16px] min-h-[32px] grow'
          />
        )}

        {!messageContent && !isOptionsMenuVisible && !isRecordingAudio && (
          <div className='flex flex-row items-center gap-sm'>
            {!isOptionsMenuVisible && (
              <div className='flex flex-row items-center gap-sm'>
                <button className='flex justify-center items-center' onClick={() => setIsOptionsMenuVisible(!isOptionsMenuVisible)}>
                  <MaterialSymbol
                    icon='add'
                    color='#B27C1F'
                    size={24}
                  />
                </button>
                <button className='flex justify-center items-center'>
                  <MaterialSymbol
                    icon='camera_alt'
                    color='#B27C1F'
                    size={24}
                    onClick={() => { setIsCameraActive(true) }}
                  />
                </button>
              </div>
            )}
            <button className='flex justify-center items-center w-[40px] h-[40px] rounded-full bg-yellow-3' onClick={startAudioRecording}>
              <MaterialSymbol
                icon='mic'
                color='#FBFCF8'
                size={24}
              />
            </button>
          </div>
        )}

        {messageContent && (
          <div className='flex flex-row items-center gap-sm'>
            <button className='flex justify-center items-center w-[40px] h-[40px] rounded-full bg-yellow-3' onClick={sendMessage}>
              <MaterialSymbol
                icon='send'
                color='#FBFCF8'
                size={24}
              />
            </button>
          </div>
        )}

        {isRecordingAudio && (
          <div className='flex flex-row items-center grow shrink gap-xs'>
            <MaterialSymbol
              icon='mic'
              color='#972F1E'
              size={24}
            />
            <Body size='lg' style='text-gray-3'>{formatRecordingTime(recordingTime)}</Body>
          </div>
        )}

        {isRecordingAudio && (
          <div className='flex flex-row items-center gap-sm'>
            <button className='flex justify-center items-center' onClick={cancelAudioRecording}>
              <MaterialSymbol
                icon='close'
                color='#A3A799'
                size={24}
              />
            </button>
            <button className='flex justify-center items-center w-[40px] h-[40px] rounded-full bg-yellow-3' onClick={handleSendAudio}>
              <MaterialSymbol
                icon='send'
                color='#FBFCF8'
                size={24}
              />
            </button>
          </div>
        )}

        {isOptionsMenuVisible && !messageContent && (
            <OptionsMenu setChatContent={setChatContent} />
        )}
      </div>

    {/* {isMediaViewerVisible && (
      <MediaViewer mediaUri={mediaUri} handleCloseMediaViewer={handleCloseMediaViewer} />
    )} */}
  </div>
  )
}

export default ChatTypeBar