import { useState, useEffect } from 'react';
import InputFilter from '../../components/inputs/InputFilter';
import ButtonPrimary from '../../components/actions/ButtonPrimary';


const SearchBar = ({ onSearch, onFilterChange, filterOptions, defaultFilterValue, initialSearchTerm, filter }) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm || '');
  const [currentFilter, setFilter] = useState(defaultFilterValue || '');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = () => {
    onSearch(searchTerm);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    onFilterChange(newFilter);
  };

  useEffect(() => {
    setFilter(filter);
  }, [filter]);

  return (
    <div className="flex items-center gap-sm w-full">
      <div className='flex gap-xxs w-full'>
        <input
          type="text"
          placeholder="Buscar"
          value={searchTerm}
          onChange={handleSearchChange}
          className="h-xl p-sm bg-green-1 rounded-full flex-grow focus:outline-none placeholder:text-yellow-3"
        />
        <ButtonPrimary
          onClick={handleSearchClick}
          icon='search'
        />
      </div>
      {filterOptions && filterOptions.length > 0 && (
        <InputFilter
          options={filterOptions}
          value={currentFilter}
          onChange={handleFilterChange}
        />
      )}
    </div>
  );
};

export default SearchBar