import { useState, useEffect } from 'react'

import { useChatContext } from '../../../pages/Protected/Chats'
import ChatTopBar from './ChatTopBar'
import ChatScrollArea from './ChatScrollArea'
import ChatTypeBar from './ChatTypeBar'
import Body from '../../typography/Body'
import SearchBar from '../../layout/SearchBar'
import defaultProfilePhoto from '../../../assets/images/profile.jpg'
import pattern from '../../../assets/images/pattern.png'
import { getMessages } from '../../../api/chats'
import { fetchProfilePhoto } from '../../../api/users'

const ChatScreen = () => {
  const {
    account,
    setError,
    chat, setChat,
    shouldRefreshChat, setShouldRefreshChat,
    isSearchBarVisible,
    isCameraActive,
    setProfilePhotoMap, profilePhotoMapSyncControl,
    isOptionsMenuVisible, setIsOptionsMenuVisible
  } = useChatContext()

  const [chatContent, setChatContent] = useState([])
  const [chatLength, setChatLength] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [currentChat, setCurrentChat] = useState(null);
  const [lastMessageFromRender, setLastMessageFromRender] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSelected, setIsSelected] = useState(false); // Adicionei o estado para isSelected

  const renderTypeBar = Boolean(!isCameraActive && chat.producer?.account?.responsibles?.includes(account.email))

  const fetchMessages = async () => {
    if (isLoading) return;
  
    if (chatContent.length === 0) {
      setIsLoading(true);
    }
  
    getMessages({ 
      producerAccountId: chat.producerAccountId,
      orderBy: 'chatContentCreationDate',
      orderDirection: 'desc',
      search: searchTerm || undefined,
      sentBeforeOrWithMessage: lastMessageFromRender
    })
      .then(response => {
        if (response.links.metaData.total === 0) {
          setChatContent([]);
          setIsLoading(false);
          return;
        }
  
        if (!chatLength) {
          setChatLength(response.links.metaData.total);
        }
  
        if (chatContent.length <= response.links.metaData.total) {
          setLastMessageFromRender(response.data[response.data.length - 1]?.id);
          setChatContent(prevContent => [...response.data.reverse(), ...prevContent]);
          getProfilePhotos(response.data);
        }
      })
      .catch(e => {
        setError(e.message);
        setChat({});
      })
      .finally(() => {
        if (chatContent.length === 0) {
          setIsLoading(false);
        }
      });
  };   
      
  useEffect(() => {
    if (!chat.producerAccountId) {
      setIsSelected(false);
    }

    if (chat.producerAccountId && chat.producerAccountId !== currentChat) {
      setCurrentChat(chat.producerAccountId);
      setChatContent([]);
    }

    if (chat.producerAccountId && !chatContent.length) {
      setChatLength(0);
      setNextPage(1);
      setIsSelected(true);
      setIsLoading(true);
      setLastMessageFromRender(undefined);
      fetchMessages();
    }

    if (chat.producerAccountId && chatContent.length) {
      setIsLoading(false);
    }
  }, [chat.producerAccountId, chatContent.length]);

  const getProfilePhotos = async (messageList) => {
    const promises = messageList.map(async (message) => {
      if (message.type === 'changeLog') return;
  
      if (!profilePhotoMapSyncControl.has(message.sender.id)) {
        profilePhotoMapSyncControl.add(message.sender.id);

        if (!message.sender.profilePhoto) {
          setProfilePhotoMap(prev => ({ ...prev, [message.sender.id]: defaultProfilePhoto }));
          return;
        }
  
        const response = await fetchProfilePhoto(message.sender.profilePhoto);
        setProfilePhotoMap(prev => ({ ...prev, [message.sender.id]: response || defaultProfilePhoto }));
      }
    });
  
    await Promise.all(promises);
  }
  
  useEffect(() => {
    if (shouldRefreshChat) {
      setShouldRefreshChat(false);
      setChatContent([]);
      setLastMessageFromRender(undefined);
    }
  }, [chat, shouldRefreshChat]);

  const handleSearchMessages = (search) => {
    setSearchTerm(search);
    setIsLoading(true);
    setChatContent([]);
    setLastMessageFromRender(undefined);
  };

  return (
    <div className='flex w-full grow bg-green-1'>
      <div
        className='flex-1 flex flex-col w-full grow bg-repeat'
        style={{
          backgroundImage: `url(${pattern})`,
          backgroundSize: '375px',
        }}
      >
        {isSelected ? (
          <div className='flex grow w-full flex-col max-h-screen' onClick={() => { if (isOptionsMenuVisible) setIsOptionsMenuVisible(false) }}>
            <div className='flex flex-row justify-between w-full'>
              <ChatTopBar />
            </div>

            {isSearchBarVisible && !isCameraActive && (
              <div className='flex flex-row justify-start items-center bg-white-1 p-sm gap-xxs'>
                <SearchBar
                  className='w-full'
                  onSearch={handleSearchMessages}
                  initialSearchTerm={''}
                />
              </div>
            )}

            {isLoading && chatContent.length === 0 && (
              <div className='flex items-center px-sm justify-center h-full w-full'>
                Carregando...
              </div>
            )}

            {!isLoading && (
              <ChatScrollArea
                chatContent={chatContent}
                setChatContent={setChatContent}
                fetchMessages={fetchMessages}
                chatLength={chatLength}
              />
            )}

            {renderTypeBar && (
              <div className='justify-self-end'>
                <ChatTypeBar setChatContent={setChatContent} />
              </div>
            )}
          </div>
        ) : (
          <div className='flex items-center justify-center w-full h-full'>
            <Body size='lg' style='text-center'>
              Nenhum chat selecionado
            </Body>
          </div>
        )}
      </div>
    </div>
  )
}

export default ChatScreen;
