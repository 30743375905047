import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function WYSIWYG ({value, onChange}) {
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }],
      ['bold', 'italic', 'underline'],
      [{ align: '' },{ align: 'center' },{ align: 'right' },{ align: 'justify' }],
      [{ list: 'ordered'}, { list: 'bullet' }],
    ],
  };

  return (
    <div>
      <ReactQuill
        value={value}
        onChange={onChange}
        modules={modules}
      />
      <style>
        {`
          .quill {
            border-radius: 4px !important;
            overflow: hidden;
          }
          .ql-toolbar {
            background-color: #B27C1F;
          }
          .ql-toolbar .ql-header *{
            fill: #F5F8EC !Important;
            stroke: transparent !Important;
          }
          .ql-toolbar .ql-formats *{
            stroke: #F5F8EC;
          }
          .ql-toolbar .ql-formats .ql-active:not(.ql-header) *{
            stroke: #E0AA4F !important;
          }
          .ql-toolbar .ql-formats .ql-active.ql-header *{
            fill: #E0AA4F !Important;
          }
          .ql-container {
            background-color: #F5F8EC;
            font-family: 'League Spartan', sans-serif;
            border: 0 !important;
            color: #151A0A !important;
          }
        `}
      </style>
    </div>
  );
}

export default WYSIWYG;
