/** @type {string} */
let string

/** @type {string|undefined} */
let optString

/** @type {number} */
let number

/** @type {number|undefined} */
let optNumber

/** @type {boolean} */
let boolean

/** @type {boolean|undefined} */
let optBoolean

/** @type {"true"|"false"} */
let stringBoolean

/** @type {"asc"|"desc"|undefined} */
let optOrderDirections

/** @type {{ [key: string]: ({}|string)[] } | undefined} */
let referencesObject

/** @type {Array<Blob> | undefined} */
let optBlob

/** @type {Array<{}>|[]|undefined} */
let datObject

/** @type {Array<number>|null} */
let referencesIdArray

/** @type {"alta"|"indefinida"} */
let priorityLevel

export const QueryParams = Object.freeze({
  page: optNumber,
  limit: optNumber,
  paginate: optBoolean,
  orderBy: optString,
  orderDirection: optOrderDirections,
  getReferences: optBoolean,
  search: optString
});

export const Service = Object.freeze({
  priorityLevel,
  serviceTypeId: optNumber
});

export const Message = Object.freeze({
  hasMedia: boolean,
  content: optString,
  messageAttachment: optBlob
});

export const GetServicesParams = Object.freeze({
  ...QueryParams
});

export const GetAppointmentsParams = Object.freeze({ 
  ...QueryParams
});

export const GetChatsParams = Object.freeze({
  responsibleId: string,
  ...QueryParams
});

export const GetMessagesParams = Object.freeze({
  producerAccountId: optString,
  sentBeforeOrWithMessage: optString,
  sentAfterOrWithMessage: optString,
  ...QueryParams
});

export const GetContentsParams = Object.freeze({ 
  ...QueryParams
});

export const Content = Object.freeze({
  title: string,
  categories: referencesIdArray,
  types: referencesIdArray,
  excerpt: string,
  articleContent: optString,
  url: optString
});

export const ContentBody = Object.freeze({
  content: { ...Content }
});

export const APIResponse = Object.freeze({
  links: {
    first: optString,
    self: string,
    prev: optString,
    next: optString,
    metaData: {
      currentPage: optNumber,
      lastPage: optNumber,
      total: number
    }
  },
  data: datObject,
  references: referencesObject
});