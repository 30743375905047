import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { MaterialSymbol } from 'react-material-symbols';

const MediaModal = ({ mediaUri, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (mediaUri) {
      setIsOpen(true);
    }
  }, [mediaUri]);

  if (!isOpen) return null;

  return createPortal(
    <div className="fixed inset-0 flex items-center justify-center bg-black-1 bg-opacity-50 z-50">
      <div className="flex flex-col bg-black-1 rounded-sm w-full max-w-screen-md relative max-h-[calc(100vh-96px)] overflow-hidden">
        {/* Header */}
        <div className="flex w-full justify-end items-center h-xl px-sm shrink-0 absolute">
          <button onClick={() => {
            setIsOpen(false);
            onClose();
          }} className='flex hover:opacity-50'>
            <MaterialSymbol icon="close" size={16} color='#A3A799' />
          </button>
        </div>
        {/* Content */}
        <div className="flex justify-center items-center grow shrink">
          <img src={mediaUri} alt="Preview" className="w-100 h-100 max-w-full max-h-full object-contain"/>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default MediaModal;