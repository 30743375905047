import { MaterialSymbol } from 'react-material-symbols'

import { useChatContext } from '../../../pages/Protected/Chats'
import Body from '../../typography/Body'
import { postMessage } from '../../../api/chats'

const OptionsMenu = ({ setChatContent }) => {
  const {
    currentUser,
    setError, 
    chat,
    setIsCameraActive,
    chatList, setChatList,
  } = useChatContext()

  const acceptedMediaFormats = '.jpg,.jpeg,.gif,.png,.mp4,.webp,.mov,.avi'
  const acceptedDocumentFormats = '.pdf,.doc,.docx,.ppt,.pptx,.xlsx,.xls,.odt,.txt,.rtf'

  const upload = async (accpetedFormats) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = accpetedFormats
  
    input.onchange = (event) => {
      const file = event.target.files[0]
      if (file) {
        const blob = new Blob([file], { type: file.type })
        saveUpload(blob)
      }
    }
  
    input.click()
  }

  const saveUpload = async (blob) => {
    postMessage(chat.producer.account.id, {
      hasMedia: true,
      messageAttachment: [blob]
    })
    .then (response => {
      const message = response.data?.at(0)
      const content = {
        type: 'messageSent',
        message: message,
        sender: currentUser.id,
        hasMedia: message.hasMedia,
        content: message.content,
        media: message.media,
        createdAt: message.createdAt
      }

      setChatContent(prev => ([...prev, content]))
      
      const chatListClone = structuredClone(chatList)
      const chatToEdit = chatListClone.find(item => item.producer.account.id === chat.producer.account.id)
      chatToEdit.message = message
      chatToEdit.lastMessage = message.content
      setChatList(chatListClone)
    })
    .catch(e => {
      console.error(e)
      setError('Erro ao postar foto')
    })
  }

  return (
    <div className='flex flex-row items-stretch p-md pb-lg justify-center w-full'>
      <button className='flex justify-center items-center w-1/4 gap-xs' onClick={() => { upload(acceptedDocumentFormats) }}>
        <MaterialSymbol
          icon='attach_file'
          color='#B27C1F'
          size={32}
        />
        <Body size='lg' style='text-yellow-3'>Anexo</Body>
      </button>
      <button className='flex justify-center items-center w-1/4 gap-xs' onClick={() => { setIsCameraActive(true) }}>
        <MaterialSymbol
          icon='camera_alt'
          color='#B27C1F'
          size={32}
        />
        <Body size='lg' style='text-yellow-3'>Câmera</Body>
      </button>
      <button className='flex justify-center items-center w-1/4 gap-xs' onClick={() => { upload(acceptedMediaFormats) }}>
        <MaterialSymbol
          icon='image'
          color='#B27C1F'
          size={32}
        />
        <Body size='lg' style='text-yellow-3'>Mídia</Body>
      </button>
      {/* {currentUser.role === 'technician' && (
        <button className='flex justify-center items-center w-1/4 gap-xs'>
          <MaterialSymbol
            icon='event'
            color='#B27C1F'
            size={32}
            // TODO
            onClick={() => { console.log('Agendar visita') }}
          />
          <Body size='lg' style='text-yellow-3'>Visita</Body>
        </button>
      )} */}
    </div>
  )
}

export default OptionsMenu
