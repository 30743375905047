import { useState } from 'react'
import { MaterialSymbol } from 'react-material-symbols'

import { useChatContext } from '../../../pages/Protected/Chats'
import Label from '../../typography/Label'
import defaultProfilePhoto from '../../../assets/images/profile.jpg'
import { putService } from '../../../api/chats'

const ChatTopBar = () => {
  const { 
    setError,
    chat,
    chatList, setChatList,
    isSearchBarVisible, setIsSearchBarVisible,
    profilePhotoMap
  } = useChatContext()
  const [priorityLevel, setPriorityLevel] = useState(chat.servicePriorityLevel ?? 'indefinida')

  const handleTogglePriority = async () => {
    const newPriorityLevel = priorityLevel === 'alta' ? 'indefinida' : 'alta'

    await putService(chat.serviceId, { priorityLevel: newPriorityLevel })
      .then(_=> {
        setPriorityLevel(newPriorityLevel)

        const chatListClone = structuredClone(chatList)
        const chatToEdit = chatListClone.find(item => item.producer.account.id === chat.producer.account.id)
        chatToEdit.servicePriorityLevel = newPriorityLevel
        chatToEdit.service.priorityLevel = newPriorityLevel
        setChatList(chatListClone)
      })
      .catch(e => {
        setError(e)
      })
  }

  const toggleSearchBar = () => {
    setIsSearchBarVisible(!isSearchBarVisible)
  }


  return (
    <div className='flex flex-row w-full justify-start items-center bg-white-1 pl-sm py-sm'>
      <div className='flex flex-row justify-start grow items-center h-full overflow-hidden gap-xs'>
        <img
          src={chat.beneficiaryProfilePhoto ? profilePhotoMap[chat.producer.user.id] : defaultProfilePhoto}
          className='h-[32px] w-[32px] bg-gray-2 bg-gray-1 rounded-full'
          alt='Profile'
        />
        <Label size='lg' style='text-green-4'>{chat.beneficiaryNickname || chat.beneficiaryName || chat.propertyAlias}</Label>
      </div>
    
      <div className='flex flex-row items-center pr-sm'  style={{ gap: 16 }}>
        {/* Prioridade */}
        <button className={`flex justify-center items-center ${priorityLevel === 'alta' ? 'w-[24px] h-[24px] rounded-full bg-yellow-3' : ''}`} onClick={handleTogglePriority}>
          <MaterialSymbol
            icon='priority_high'
            color={priorityLevel === 'alta' ? '#FBFCF8' : '#B27C1F'}
            size={priorityLevel === 'alta' ? 16 : 24}
          />
        </button>
        {/* Abre agendar visita  */}
        {/* <button className='flex justify-center items-center'>
          <MaterialSymbol
            icon='event'
            color='#B27C1F'
            size={24}
            // TODO
            onClick={() => { console.log ('Agendar visitas')}}
          />
        </button> */}
        {/* Abre barra de busca  */}
        <button
          className='flex justify-center items-center'
          onClick={toggleSearchBar}>
          <MaterialSymbol
            icon='search'
            color='#B27C1F'
            size={24}
          />
        </button>
      </div>
  </div>
  )
}

export default ChatTopBar