import React, { useRef, useState, useEffect } from 'react';

const ChatInputText = ({ name, label, placeholder, value: propValue, autoComplete, onChangeText: propOnChangeText, icon }) => {
  const [localValue, setLocalValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const textInputRef = useRef(null);

  const handlePress = () => {
    if (textInputRef.current) {
      textInputRef.current.focus();
    }
  };

  const handleChangeText = (event) => {
    const newValue = event.target.value;
    if (propOnChangeText) {
      propOnChangeText(newValue);
    } else {
      setLocalValue(newValue);
    }
  };

  const value = propValue !== undefined ? propValue : localValue;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (textInputRef.current && !textInputRef.current.contains(event.target)) {
        setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`flex flex-col px-sm rounded-xs justify-center h-xl w-full cursor-text ${isFocused ? '' : ''}`}
      onClick={handlePress}
    >
      <input
        type="text"
        name={name}
        placeholder={placeholder}
        value={value}
        autoComplete={autoComplete}
        onChange={handleChangeText}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={`leading-[125%] text-[16px] text-black-1 focus:outline-none focus-visible:outline-none bg-transparent ${isFocused || value ? 'h-[24px]' : 'h-0'}`}
        ref={textInputRef}
      />
    </div>
  );
};

export default ChatInputText;
