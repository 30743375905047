import { useState } from 'react';
import { format } from 'date-fns';
import { MaterialSymbol } from 'react-material-symbols';
import Body from '../../typography/Body';
import Label from '../../typography/Label';
import MediaModal from '../../overlay/MediaModal';

const ImagePreview = ({ item, imageRef, errorOnLoad }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMediaUri, setSelectedMediaUri] = useState(null);

  const handleOpenMediaViewer = (mediaUri) => {
    console.log('abrir');
    setSelectedMediaUri(mediaUri);
    setIsModalOpen(true);
    console.log(mediaUri,isModalOpen);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedMediaUri(null);
  };

  return (
    <>
      <button
        className={`${item.content && item.content !== ' ' ? 'rounded-t-xs' : 'rounded-xs'} flex flex-row justify-center items-center w-[1000px] max-w-[320px] aspect-[4/3] bg-black-1 relative hover:opacity-90`}
        style={{ boxShadow: '0px 2px 4px rgba(21, 26, 10, 0.25)' }}
        onClick={() => handleOpenMediaViewer(imageRef.current.src)}
      >
        {!errorOnLoad && (
          <img
            className={`${item.content && item.content !== ' ' ? 'rounded-t-xs' : 'rounded-xs'} h-full w-full object-cover`}
            ref={imageRef}
            alt=''
          />
        )}

        {errorOnLoad && (
          <div className='flex flex-col gap-xxxs items-center rounded-xs w-full h-full justify-center absolute'>
            <div 
              className={`flex items-center rounded-xs w-full h-full justify-center absolute bg-black-1`}
            />
            <MaterialSymbol
              icon='broken_image'
              color='#FBFCF8'
              size={32}
            />
            <Body style='text-white-1'>
              Erro ao carregar imagem
            </Body>
          </div>
        )}

        {!item.content && (
          <Label size='sm' style='text-white-1 self-end absolute bottom-xxs right-xs'>{format(new Date(item.createdAt), 'HH:mm')}</Label>
        )}
      </button>

      {isModalOpen && (
        <MediaModal mediaUri={selectedMediaUri} onClose={handleCloseModal} />
      )}
    </>
  );
};

export default ImagePreview;