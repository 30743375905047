import { format } from 'date-fns'

import Media from './Media'
import Body from '../../typography/Body'
import Label from '../../typography/Label'

const Message = ({item}) => {
  if (item.message.media) {
    return (
      <Media item={item} />
    )
  }

  return (
    <div>
      {/* Message */}
      <div className={`flex grow shrink ${item.type === 'messageReceived' ? 'items-start' : 'items-end'}`} style={{ gap: 4 }}>
          <div
            className={`flex flex-col px-xs py-xxs ${item.type === 'messageReceived' ? 'bg-white-1' : 'bg-green-2'} ${item.hasMedia ? ' rounded-b-xs' : 'rounded-xs'}`}
            style={{ boxShadow: '0px 2px 4px rgba(21, 26, 10, 0.25)' }}
          >
            {item.type === 'messageReceived' && (
              <Label size='md' style='text-green-4'>{item.sender.name}</Label>
            )}
            <Body size='lg'>{item.content}</Body>
            <Label size='sm' style='text-gray-3 self-end'>{format(new Date(item.createdAt), 'HH:mm')}</Label>
          </div>        
      </div>
    </div>
  )
}

export default Message
