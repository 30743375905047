import { MaterialSymbol } from 'react-material-symbols';
import Body from '../typography/Body';

const ContentField = ({ label, value, icon, style, unit }) => {
    return (
      <div className={`flex flex-col gap-xxxs ${style}`}>
        <div className="flex flex-row items-center" style={{ gap: 4 }}>
          <MaterialSymbol
            icon={icon}
            color="#A3A799"
            size={12}
          />
          <Body size="sm" style="text-gray-3">{label}</Body>
        </div>
        <Body size="lg">{value !== null ? (unit !== null && unit !== undefined ? `${value} ${unit}` : value) : '-'}</Body>
      </div>
    );
  };

export default ContentField;
