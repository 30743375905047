import React, { useState, useEffect } from 'react';
import Display from '../../components/typography/Display';
import Card from '../../components/layout/Card';
import { getSessionData } from '../../api/auth';
import { getAccounts } from '../../api/accounts';
import { getServices } from '../../api/chats';
import { getAppointments } from '../../api/appointments';
import ReactECharts from 'echarts-for-react';
import DateFilter, { DateContext } from '../../components/inputs/DateFilter';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()
  
  const [totalServices, setTotalServices] = useState();
  const [totalServicesByStatus, setTotalServicesByStatus] = useState({});
  const [partialServicesByServiceType, setPartialServicesByServiceType] = useState({});
  const [partialServicesWithServiceType, setPartialServicesWithServiceType] = useState();
  const [partialServices, setPartialServices] = useState();
  const [allServicesByCreationDate, setServicesByCreationDate] = useState({});
  const [isTotalServicesLoading, setIsTotalServicesLoading] = useState(true);

  const [totalAppointments, setTotalAppointments] = useState();
  const [partialAppointmentsByDayOfTheWeek, setPartialAppointmentsByDayOfTheWeek] = useState({});
  const [partialAppointmentsByStatus, setPartialAppointmentsByStatus] = useState({});
  const [partialAppointmentsByServiceType, setPartialAppointmentsByServiceType] = useState({});
  const [partialAppointments, setPartialAppointments] = useState();
  const [allAppointmentsByCreationDate, setAppointmentsByCreationDate] = useState({});
  const [isTotalAppointmentsLoading, setIsTotalAppointmentsLoading] = useState(true);

  const [totalAdminAccounts, setTotalAdminAccounts] = useState();
  const [isTotalAdminsLoading, setIsTotalAdminsLoading] = useState(true);
  const [totalTechnicianAccounts, setTotalTechnicianAccounts] = useState();
  const [isTotalTechniciansLoading, setIsTotalTechniciansLoading] = useState(true);
  const [totalProducerAccounts, setTotalProducerAccounts] = useState();
  const [isTotalProducersLoading, setIsTotalProducersLoading] = useState(true);

  const getTotalAdmins = async () => {
    try {
      const result = await getAccounts({ permission: 'admin' });
      setTotalAdminAccounts(result.links.metaData.total);
    } catch (error) {
      console.error('Erro ao buscar contas', error);
    } finally {
      setIsTotalAdminsLoading(false);
    }
  }

  const getTotalTechnicians = async () => {
    try {
      const result = await getAccounts({ permission: 'technician' });
      setTotalTechnicianAccounts(result.links.metaData.total);
    } catch (error) {
      console.error('Erro ao buscar contas', error);
    } finally {
      setIsTotalTechniciansLoading(false);
    }
  }

  const getTotalBeneficiaries = async () => {
    try {
      const result = await getAccounts({ permission: 'producer' });
      setTotalProducerAccounts(result.links.metaData.total);
    } catch (error) {
      console.error('Erro ao buscar contas', error);
    } finally {
      setIsTotalProducersLoading(false);
    }
  }

  const getTotalServices = async () => {
    try {
      const response = await getServices({paginate: 'false', orderBy: 'serviceStartDate', orderDirection: 'asc', getReferences: 'true'});
      const total = response.links.metaData.total
      const serviceTypes = {}
      response?.references.serviceTypes.forEach(serviceType => (serviceTypes[serviceType.id] = serviceType.value))
      
      const SERVICE_STATUS_TEMPLATE = {
        'aberto' : 0,
        'finalizado': 0
      }; Object.freeze(SERVICE_STATUS_TEMPLATE)

      let
        allServicesByStatusCount = { ...SERVICE_STATUS_TEMPLATE },
        partialServicesByStatusCount = { ...SERVICE_STATUS_TEMPLATE },
        partialServicesByServiceTypeCount = {},
        partialServicesWithServiceTypeCount = 0,
        partialServicesCount = 0,
        servicesByCreationDate = {}

      const fromDateString = fromDate.toLocaleDateString('pt-br')
      const toDateString = toDate.toLocaleDateString('pt-br')
      
      servicesByCreationDate[fromDateString] = 0
      response.data.forEach(service => {
        allServicesByStatusCount[service.status] += 1
        
        const timestamp = new Date(service.startTimestamp)
        const timestampString = timestamp.toLocaleDateString('pt-br')
        if (timestamp >= fromDate && timestamp < toDate) {
          partialServicesCount += 1
          partialServicesByStatusCount[service.status] += 1

          const dict = partialServicesByServiceTypeCount
          const key = serviceTypes[service.serviceTypeId]
          if (key) {
            partialServicesWithServiceTypeCount += 1
            dict[key] ? dict[key] += 1 : dict[key] = 1
          }

          servicesByCreationDate[timestampString] ? servicesByCreationDate[timestampString] += 1 : servicesByCreationDate[timestampString] = 1
        } 
      });
      if (!servicesByCreationDate[toDateString]) {
        servicesByCreationDate[toDateString] = 0;
      }
      
      setTotalServicesByStatus(allServicesByStatusCount);
      setTotalServices(total)

      setPartialServicesByServiceType(partialServicesByServiceTypeCount)
      setPartialServicesWithServiceType(partialServicesWithServiceTypeCount)
      setPartialServices(partialServicesCount)

      setServicesByCreationDate(servicesByCreationDate)
    } catch (error) {
      console.error('Erro ao buscar atendimentos', error);
    } finally {
      setIsTotalServicesLoading(false);
    }
  }

  const getTotalAppointments = async () => {
    try {
      const response = await getAppointments({paginate: 'false', orderBy: 'appointmentCreationDate', orderDirection: 'asc', getReferences: 'true'});
      const total = response.links.metaData.total
      const serviceTypes = {}
      response?.references.serviceTypes.forEach(serviceType => (serviceTypes[serviceType.id] = serviceType.value))
      
      const APPOINTMENT_STATUS_TEMPLATE = {
        'confirmado' : 0,
        'pendente': 0,
        'cancelado': 0,
        'concluído': 0
      }; Object.freeze(APPOINTMENT_STATUS_TEMPLATE)

      let
        partialAppointmentsByStatusCount = { ...APPOINTMENT_STATUS_TEMPLATE },
        partialAppointmentsByServiceType = {},
        partialAppointmentsByDaysOfTheWeekCount = [0, 0, 0, 0, 0, 0, 0],
        partialAppointmentsCount = 0,
        appointmentsByCreationDate = {}

      const fromDateString = fromDate.toLocaleDateString('pt-br')
      const toDateString = toDate.toLocaleDateString('pt-br')

      appointmentsByCreationDate[fromDateString] = 0
      response.data.forEach(appointment => {
        const timestamp = new Date(appointment.createdAt)
        const timestampString = timestamp.toLocaleDateString('pt-br')
        const dayOfWeek = timestamp.getDay()
        if (timestamp >= fromDate && timestamp < toDate) {
          partialAppointmentsCount += 1
          partialAppointmentsByStatusCount[appointment.status] += 1
          partialAppointmentsByDaysOfTheWeekCount[dayOfWeek] += 1

          const dict = partialAppointmentsByServiceType
          const key = serviceTypes[appointment.serviceTypeId]
          dict[key] ? dict[key] += 1 : dict[key] = 1
          
          appointmentsByCreationDate[timestampString] ? appointmentsByCreationDate[timestampString] += 1 : appointmentsByCreationDate[timestampString] = 1
        } 
      });
      if (!appointmentsByCreationDate[toDateString]) {
        appointmentsByCreationDate[toDateString] = 0;
      }
      
      const l = partialAppointmentsByDaysOfTheWeekCount
      const partialAppointmentsByDaysOfTheWeek = {
        'Segunda': l[1], 'Terça': l[2], 'Quarta': l[3],
        'Quinta': l[4], 'Sexta': l[5], 'Sábado': l[6],
        'Domingo': l[0]
      }

      setTotalAppointments(total)

      setPartialAppointmentsByStatus(partialAppointmentsByStatusCount)
      setPartialAppointmentsByServiceType(partialAppointmentsByServiceType)
      setPartialAppointmentsByDayOfTheWeek(partialAppointmentsByDaysOfTheWeek)
      setPartialAppointments(partialAppointmentsCount)

      setAppointmentsByCreationDate(appointmentsByCreationDate)
    } catch (error) {
      console.error('Erro ao buscar visitas', error);
    } finally {
      setIsTotalAppointmentsLoading(false);
    }
  }

  const getOptionForLineGraph = (dataObject) => {
    return {
      series: [
        {
          type: 'line',
          data: Object.values(dataObject),
          smooth: true,
          symbol: 'none',
          lineStyle: {
            width: 3
          }
        }
      ],
      tooltip: {
        show: true,
        trigger: 'axis'
      },
      xAxis: {
        type: 'category',
        data: Object.keys(dataObject),
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
      },
      yAxis: {
        type: 'value',
        // show: false,
        splitLine: {
          // show: false
        }
      },
      grid: {
        left: '5%',
        right: '5%',
        top: '10%',
        bottom: '10%'
      }
    }
  }

  const getOptionForPieGraph = (dataObject) => {
    return {
      series: [
        {
          type: 'pie',
          data: Object.entries(dataObject).map(([name, value]) => ({ name, value })),
          smooth: true,
          symbol: 'none',
          radius: ['65%', '90%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          // emphasis: {
          //   label: {
          //     show: true,
          //     fontSize: 40,
          //     fontWeight: 'bold'
          //   }
          // },
        }
      ],
      tooltip: {
        show: true,
        trigger: 'item'
      }
    }
  }

  const getDateDiff = () => {
    const diffTime = Math.abs(toDate - fromDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays
  }

  useEffect(() => {
    const sessionData = getSessionData();
    if (sessionData) {
      setUser(sessionData.users[0] || null);
    }
    setIsLoading(false); 
  }, []);

  useEffect(() => {
    if (fromDate && toDate) {
      getTotalAdmins();
      getTotalTechnicians();
      getTotalBeneficiaries();
      getTotalServices();
      getTotalAppointments();
    }
  }, [fromDate, toDate])

  if (isLoading) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Display size='md' style="text-gray-3">Carregando...</Display>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full p-xl gap-lg overflow-auto">
      <div className='flex justify-between'>
        <Display size='md' style="text-green-4">Olá, {user ? user.name.split(' ')[0] : 'Visitante'}!</Display>
        <DateContext.Provider value={[fromDate, toDate, setFromDate, setToDate]}>
          <DateFilter/>
        </DateContext.Provider>
      </div>
      <div className='flex flex-col gap-sm'>
        <div className='flex gap-sm'>
          <div className='flex gap-sm w-2/3'>
            <div className='w-1/2'>
              <Card title='Administradores cadastrados'>
                {isTotalAdminsLoading ? (
                  <div className="flex items-center justify-center h-full">
                    <span>Carregando...</span>
                  </div>
                ) : (
                  <Display>{totalAdminAccounts}</Display>
                )}
              </Card>
            </div>
            <div className='w-1/2'>
              <Card title='Técnicos cadastrados'>
                {isTotalTechniciansLoading ? (
                  <div className="flex items-center justify-center h-full">
                    <span>Carregando...</span>
                  </div>
                ) : (
                  <Display>{totalTechnicianAccounts}</Display>
                )}
              </Card>
            </div>
          </div>
          <div className='flex w-1/3'>
            <Card title='Beneficiários cadastrados'>
              {isTotalProducersLoading ? (
                <div className="flex items-center justify-center h-full">
                  <span>Carregando...</span>
                </div>
              ) : (
                <Display>{totalProducerAccounts}</Display>
              )}
            </Card>
          </div>
        </div>
        <div className='flex gap-sm'>
          <div className='flex w-2/3'>
            <Card title='Atendimentos'>
              {isTotalServicesLoading ? (
                <div className="flex items-center justify-center h-full">
                  <span>Carregando...</span>
                </div>
              ) : (
                <div className="w-full h-full">
                  <Display size='md'>
                    {partialServices}
                  </Display>
                  <ReactECharts
                    option={getOptionForLineGraph(allServicesByCreationDate)}
                    theme={'default'}
                  />
                </div>
              )}
            </Card>
          </div>
          <div className='flex w-1/3'>
            <Card title='Atendimentos por status'>
              {isTotalServicesLoading ? (
                <div className="flex items-center justify-center h-full">
                  <span>Carregando...</span>
                </div>
              ) : (
                <div className="w-full h-full">
                  <ReactECharts
                    option={getOptionForPieGraph(totalServicesByStatus)}
                    theme={'default'}
                  />
                </div>
              )}
            </Card>
          </div>
        </div>
        <div className='flex gap-sm'>
          <div className='flex w-2/3'>
            <Card title='Visitas'>
              {isTotalAppointmentsLoading ? (
                <div className="flex items-center justify-center h-full">
                  <span>Carregando...</span>
                </div>
              ) : (
                <div className="w-full h-full">
                  <Display size='md'>
                    {partialAppointments}
                  </Display>
                  <ReactECharts
                    option={getOptionForLineGraph(allAppointmentsByCreationDate)}
                    theme={'default'}
                  />
                </div>
              )}
            </Card>
          </div>
          <div className='flex w-1/3'>
            <Card title='Visitas por tipo'>
              {isTotalAppointmentsLoading ? (
                <div className="flex items-center justify-center h-full">
                  <span>Carregando...</span>
                </div>
              ) : (
                <div className="w-full h-full">
                  <ReactECharts
                    option={getOptionForPieGraph(partialAppointmentsByServiceType)}
                    theme={'default'}
                  />
                </div>
              )}
            </Card>
          </div>
        </div>
        <div className='flex gap-sm'>
          <div className='flex w-2/3'>
            <Card title='Visitas por dias da semana'>
              {isTotalAppointmentsLoading ? (
                <div className="flex items-center justify-center h-full">
                  <span>Carregando...</span>
                </div>
              ) : (
                <div className="w-full h-full">
                  <ReactECharts
                    option={getOptionForLineGraph(partialAppointmentsByDayOfTheWeek)}
                    theme={'default'}
                  />
                </div>
              )}
            </Card>
          </div>
          <div className='flex w-1/3'>
            <Card title='Visitas por status'>
              {isTotalAppointmentsLoading ? (
                <div className="flex items-center justify-center h-full">
                  <span>Carregando...</span>
                </div>
              ) : (
                <div className="w-full h-full">
                  <ReactECharts
                    option={getOptionForPieGraph(partialAppointmentsByStatus)}
                    theme={'default'}
                  />
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
