import { getToken, getSessionData } from './auth';

const BASE_URL = '/api';

// Função para obter a lista de usuários com paginação e filtro por função
export const getUsers = async (params) => {
  try {
    const token = await getToken();
    const queryString = new URLSearchParams(params).toString();

    const response = await fetch(`${BASE_URL}/user/profile?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar usuários');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar usuários:', error);
    return { data: [], links: { metaData: { total: 0, lastPage: 0 } } };
  }
};

export const getProperties = async (params) => {
  try {
    const token = await getToken();
    const queryString = new URLSearchParams(params).toString();

    const response = await fetch(`${BASE_URL}/account/property?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar usuários');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar usuários:', error);
    return { data: [], links: { metaData: { total: 0, lastPage: 0 } } };
  }
};


// Função para obter CSV da lista de usuários
export const downloadUsersCSV = async (permission, search = '') => {
  try {
    const token = await getToken();
    const searchParam = search ? `&search=${search}` : '';


    const response = await fetch(`${BASE_URL}/user/csv?isActive=true&permission=${permission}${searchParam}`, {
      method: 'GET',
      headers: {
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao baixar CSV');
    }

    const result = await response.text();
    return result;
  } catch (error) {
    console.error('Erro ao baixar CSV:', error);
    return null;
  }
};

// Função para obter CSV da lista de properties
export const downloadPropertiesCSV = async (search = '') => {
  try {
    const token = await getToken();
    const searchParam = search ? `&search=${search}` : '';


    const response = await fetch(`${BASE_URL}/account/property/csv?isActive=true${searchParam}`, {
      method: 'GET',
      headers: {
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao baixar CSV');
    }

    const result = await response.text();
    return result;
  } catch (error) {
    console.error('Erro ao baixar CSV:', error);
    return null;
  }
};

export const getReferences = async () => {
  try {
    const token = await getToken();

    const response = await fetch(`${BASE_URL}/api/account/reference`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar referências');
    }

    const result = await response.json();
    return result.references;
  } catch (error) {
    console.error('Erro ao buscar referências:', error);
    return null;
  }
};


// Função para obter detalhes de um usuário específico
export const getUser = async (userId) => {
  try {
    const token = await getToken();

    const response = await fetch(`${BASE_URL}/user/${userId}/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Erro ao buscar detalhes do usuário');
    }

    const result = await response.json();
    const accountId = result.data.user.accountId;

    const accountResponse = await fetch(`${BASE_URL}/account/${accountId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!accountResponse.ok) {
      throw new Error('Erro ao buscar detalhes da conta');
    }

    const accountResult = await accountResponse.json();

    return {
      user: result.data,
      account: accountResult.data.account,
      references: result.references || null,
    };
  } catch (error) {
    console.error('Erro ao buscar detalhes do usuário:', error);
    return { user: null, account: null, references: null };
  }
};


// Função para obter detalhes de uma conta específica
export const getAccount = async (accountId) => {
  try {
    const token = await getToken();

    const response = await fetch(`${BASE_URL}/account/${accountId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar detalhes da conta');
    }

    const result = await response.json();

    return result.data;
  } catch (error) {
    console.error('Erro ao buscar detalhes da conta:', error);
    return { result: null };
  }
};


// Função para atualizar os dados de um usuário
export const putUser = async (accountId, userId, accountBody, userBody, responsiblesFormData) => {
  try {
    const token = await getToken();
    const sessionData = getSessionData();
    const agentUserId = sessionData?.users[0]?.id;

    let accountResult = null;
    if (accountBody) {
      const accountResponse = await fetch(`${BASE_URL}/account/${accountId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
          'agent': `${agentUserId}`,
        },
        body: JSON.stringify(accountBody),
      });

      if (!accountResponse.ok) {
        throw new Error('Erro ao atualizar a conta');
      }

      accountResult = await accountResponse.json();
    }

    let userResult = null;
    if (userId && userBody) {
      const userResponse = await fetch(`${BASE_URL}/user/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
          'agent': `${agentUserId}`,
        },
        body: JSON.stringify(userBody),
      });

      if (!userResponse.ok) {
        throw new Error('Erro ao atualizar usuário');
      }

      userResult = await userResponse.json();
    }

    let responsiblesResult = null;
    if (responsiblesFormData) {
      const responsiblesResponse = await fetch(`${BASE_URL}/account/${accountId}/responsibles`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
          'agent': `${agentUserId}`,
        },
        body: JSON.stringify(responsiblesFormData),
      });

      if (!responsiblesResponse.ok) {
        throw new Error('Erro ao atualizar responsáveis');
      }

      responsiblesResult = await responsiblesResponse.json();
    }

    return userResult || accountResult || responsiblesResult;
  } catch (error) {
    console.error('Erro ao atualizar usuário ou conta:', error);
    return null;
  }
};

export const putUserProfilePhoto = async (userId, fileInput) => {
  try {
    const token = await getToken();
    const sessionData = getSessionData();
    const agentUserId = sessionData?.users[0]?.id;

    const formData = new FormData();
    const file = fileInput.files[0];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const imageType = fileExtension === 'png' ? 'image/png' : 'image/jpeg';

    formData.append('profilePhoto', file, `photo.${fileExtension}`);

    const response = await fetch(`${BASE_URL}/user/${userId}/profile-photo`, {
      method: 'PUT',
      headers: {
        'Authorization': `${token}`,
        'agent': `${agentUserId}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Erro ao atualizar a foto do perfil');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Erro ao atualizar a foto do perfil:", error);
    return null;
  }
};


// Função para criar uma nova conta
export const postAccount = async (body) => {
  try {
    const token = await getToken();
    
    const response = await fetch(`${BASE_URL}/account/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error('Erro ao criar conta');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao criar conta:', error);
    return null;
  }
};

export const postUser = async (accountId, body) => {
  try {
    const token = await getToken();
    
    const response = await fetch(`${BASE_URL}/account/${accountId}/user/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error('Erro ao criar usuário');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao criar usuário:', error);
    return null;
  }
};


export const fetchProfilePhoto = async (photoFileName) => {
  try {
    const token = await getToken();
    const response = await fetch(`${BASE_URL}/storage/profile-photos/${photoFileName}`, {
      method: 'GET',
      headers: {
        'Authorization': `${token}`,
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      return url;
    } else {
      console.error('Erro ao buscar foto de perfil');
      return null;
    }
  } catch (error) {
    console.error('Erro ao buscar foto de perfil', error);
    return null;
  }
};

export const fetchCoverPhoto = async (photoFileName) => {
  try {
    const token = await getToken();
    const response = await fetch(`${BASE_URL}/storage/cover-photos/${photoFileName}`, {
      method: 'GET',
      headers: {
        'Authorization': `${token}`,
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      return url;
    } else {
      console.error('Erro ao buscar foto de perfil');
      return null;
    }
  } catch (error) {
    console.error('Erro ao buscar foto de perfil', error);
    return null;
  }
};
