import { useState } from 'react'
import { format } from 'date-fns'
import { MaterialSymbol } from 'react-material-symbols'

import Body from '../../typography/Body'
import Label from '../../typography/Label'

const VideoPreview = ({ item, videoRef, errorOnLoad }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [hoverVideo, setHoverVideo] = useState(false)

  const handlePlay = async () => {
    if (errorOnLoad) return

    try {
      if (videoRef.current && isPlaying) {
        await videoRef.current.pause()
        setIsPlaying(!isPlaying)
        return
      }

      if (videoRef.current) {
        await videoRef.current.play()
        setIsPlaying(!isPlaying)
      }
    } catch(_) {
      console.error('Erro ao reproduzir vídeo')
    }
  }

  return (
    <button
      className={`${item.content && item.content !== ' ' ? 'rounded-t-xs' : 'rounded-xs'} flex flex-row justify-center h-[240px] w-[360px] bg-black-1 relative`}
      style={{ boxShadow: '0px 2px 4px rgba(21, 26, 10, 0.25)' }}
      onClick={handlePlay}
      onMouseEnter={() => setHoverVideo(!hoverVideo)}
      onMouseLeave={() => setHoverVideo(!hoverVideo)}
    >
      <video
        width={360}
        className={`${item.content ? 'rounded-t-xs' : 'rounded-xs'} w-full max-h-60 max-w-[360px] object-contain`}
      >
        <source ref={videoRef} />
      </video>
      <div className='flex items-center rounded-xs w-full h-full justify-center absolute'>
          <div 
            className={`flex items-center rounded-xs w-full h-full justify-center absolute bg-black-1 ${(isPlaying || hoverVideo) ? 'opacity-0' : 'opacity-50'}`}
          />
          {!errorOnLoad && !isPlaying && (
            <MaterialSymbol
              icon='play_arrow'
              color='#FBFCF8'
              size={32}
            />
          )}
          {!errorOnLoad && isPlaying && hoverVideo && (
            <MaterialSymbol
              icon='pause'
              color='#FBFCF8'
              size={32}
            />
          )}
          {errorOnLoad && (
            <div className='flex flex-col gap-xxxs'>
              <MaterialSymbol
                icon='broken_image'
                color='#FBFCF8'
                size={32}
              />
              <Body style='text-white-1'>
                Erro ao carregar vídeo
              </Body>
            </div>
          )}
      </div>
      {!item.content && (
        <Label size='sm' style='text-white-1 self-end absolute bottom-xxs right-xs'>{format(new Date(item.createdAt), 'HH:mm')}</Label>
      )}
    </button>
  )
}

export default VideoPreview